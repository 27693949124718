import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Theme } from "@mui/material";
import makeApp from "utils/makeApp";
import makeApolloClient from "utils/makeApolloClient";
import makeTheme from "utils/makeTheme";
import "./main.scss";

const apolloClient: ApolloClient<NormalizedCacheObject> = makeApolloClient();
const theme: Theme = makeTheme();

const App = makeApp(apolloClient, theme);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
