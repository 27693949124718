import React from "react";
import classnames from "classnames";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface AlertDialogProps {
  title?: string;
  message?: string;
  proceedButtonTitle?: string;
  cancelButtonTitle?: string;
  appearence?: string;
  open: boolean;
  handleClose: () => void;
  callback?: () => void;
}

const AlertDialog = ({
  title = "Alert",
  message = "",
  proceedButtonTitle = "continuer",
  cancelButtonTitle = "annuler",
  open = false,
  appearence = "default",
  handleClose,
  callback = () => {},
}: AlertDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`dialog-title-${title}`}
      aria-describedby={`dialog-title-${message}`}
    >
      <DialogTitle
        id="dialog-title"
        className={classnames("dialog-title", {
          [`dialog-title--${appearence}`]: appearence,
        })}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {cancelButtonTitle}
        </Button>
        <Button
          color="info"
          onClick={() => {
            handleClose();
            callback();
          }}
          autoFocus
        >
          {proceedButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
