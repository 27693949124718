import React from "react";
interface CompanyLogoProps {
  logoUrl: string;
}

const CompanyLogo = ({ logoUrl }: CompanyLogoProps) => {
  if (logoUrl === "no-logo") {
    return null;
  }
  return (
    <img className="company-logo" src={`data:image/jpeg;base64,${logoUrl}`} />
  );
};

export default CompanyLogo;
