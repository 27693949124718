import React from "react";
import Header from "./Header";
import { Order, ClientPortalConfig } from "@types";
import Footer from "./Footer/Footer";

interface LayoutProps {
  children: React.ReactNode;
  order: Order;
  clientPortalConfig: ClientPortalConfig;
}

const Layout = ({ children, order, clientPortalConfig }: LayoutProps) => {
  return (
    <div className="layout">
      <div className="layout__header">
        <Header
          order={order}
          contractorConfig={clientPortalConfig.contractorConfig}
        />
      </div>
      <div className="layout__content">{children}</div>
      <div className="layout__footer">
        <Footer officeConfig={clientPortalConfig.officeConfig} />
      </div>
    </div>
  );
};

export default Layout;
