import { createTheme, Theme } from "@mui/material";
import { frFR } from "@mui/x-date-pickers";

const makeTheme: () => Theme = () => {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: "#20274a",
        },
        secondary: {
          main: "#F9C900",
        },
      },
    },
    frFR
  );

  return theme;
};

export default makeTheme;
