import React from "react";
import Logo from "components/Logo";
import { Order, ContractorConfig } from "@types";
import CompanyLogo from "components/Logo/CompanyLogo";
import dayjs from "dayjs";
import { ORDER_STATUS } from "utils/constants";
import Divider from "components/Logo/Divider";

interface HeaderProps {
  order: Order;
  contractorConfig: ContractorConfig;
}

const Header = ({ order, contractorConfig }: HeaderProps) => {
  const timeSlot = order.timeSlot;

  const isPending = React.useMemo<boolean>(() => {
    return order.status === ORDER_STATUS.PENDING;
  }, [order.status]);

  const hasDeliveryChoice = React.useMemo<boolean>(() => {
    return Boolean(isPending || timeSlot);
  }, [isPending, timeSlot]);

  const isOrderCancelled = React.useMemo<boolean>(() => {
    return order.status === ORDER_STATUS.CANCELLED;
  }, [order.status]);

  const confirmedDate = React.useMemo<string>(() => {
    if (!timeSlot) {
      return "";
    }
    const date = dayjs(timeSlot.date).format("dddd D MMM");
    const hour_from = dayjs(timeSlot.hour_from, "HH:mm:ss").format("H:mm");
    const hour_to = dayjs(timeSlot.hour_to, "HH:mm:ss").format("H:mm");
    return `${date} ${hour_from} - ${hour_to}`;
  }, [timeSlot]);

  return (
    <div className="header">
      <Logo />
      <Divider />
      <CompanyLogo logoUrl={contractorConfig.logoUrl} />
      <div className="header__order-title">
        <div className="header__order-id">
          <div>Commande </div>
          <div>{order.id}</div>
        </div>
        <div className="header__order-ref">
          <div>Référence </div>
          <div>{order.reference}</div>
        </div>
        {hasDeliveryChoice && !isOrderCancelled && (
          <div className="header__confirmed-date">
            <div>Livraison</div>
            {isPending ? (
              <>
                <div>
                  Notre équipe a bien reçu votre demande, nous vous contacterons
                  dans les plus brefs délais <br />
                  <br />
                </div>
                <div>
                  Vous pouvez encore changer d{"'"}avis et choisir un créneau
                  parmi ceux qui vous sont proposés
                </div>
              </>
            ) : (
              <>
                <div>{confirmedDate}</div>
                <div className="header__confirmed-date__message">
                  livraison planifiée
                </div>
                <div className="header__confirmed-date__sub-message">
                  (Vous pouvez modifier ce rendez-vous)
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
