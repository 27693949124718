import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { Theme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";

import Delivery from "pages/Delivery";
import NotFound from "pages/NotFound";

const makeApp = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  theme: Theme
) => {
  dayjs.locale("fr");

  const App = () => {
    return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<NotFound />} />
                <Route path="/" element={<Delivery />} />
              </Routes>
            </BrowserRouter>
          </ApolloProvider>
        </LocalizationProvider>
        <ToastContainer position="bottom-right" autoClose={8000} />
      </ThemeProvider>
    );
  };

  return App;
};

export default makeApp;
