import { OrderArticle } from "@types";
import React from "react";

interface OrderArticlesProps {
  articles: OrderArticle[];
}

interface OrderArticleRowProps {
  article: OrderArticle;
}

const OrderArticleRow = ({ article }: OrderArticleRowProps) => {
  const installationInformation = React.useMemo<string>(() => {
    if (article.install_article) {
      return "Avec installation / montage";
    }

    if (article.drop_article) {
      return "Sans installation (Dépose)";
    }

    if (article.direct_drop_article) {
      return "Sans installation (Dépose pied du camion)";
    }

    return "";
  }, [article.drop_article, article.install_article, article.direct_drop_article]);

  return (
    <div className="order-article-row">
      <div className="order-article-row__name">{article.name}</div>
      <div className="order-article-row__quantity">{`x${article.quantity}`}</div>
      <div className="order-article-row__installation-information">
        {installationInformation}
      </div>
    </div>
  );
};

const OrderArticles = ({ articles }: OrderArticlesProps) => {
  return (
    <div className="order-articles">
      {articles.map((article) => {
        return <OrderArticleRow key={article.id} article={article} />;
      })}
    </div>
  );
};

export default OrderArticles;
