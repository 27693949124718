import React from "react";
import { useLazyQuery } from "@apollo/client";
import { FormikErrors } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useDebounce from "utils/useDebounce";
import { ClientInformationFormState } from "../ClientInformationForm";
import PostalCodesAndCitiesQuery from "./PostalCodesAndCitiesQuery.gql";

interface PostalCodeAndCityAutoCompleteProps {
  value: string;
  onChange: (
    event: any,
    value: string | null
  ) => Promise<void> | Promise<FormikErrors<ClientInformationFormState>>;
  error: boolean | undefined;
  helperText: string | false | undefined;
  disabled?: boolean | undefined;
}

const PostalCodeAndCityAutoComplete = ({
  value,
  onChange,
  ...textFieldProps
}: PostalCodeAndCityAutoCompleteProps) => {
  const [search, setSearch] = React.useState<string>("");
  const [postalCodesAndCities, setPostalCodesAndCities] = React.useState<
    string[]
  >([]);
  const debouncedSearch = useDebounce(search, 400);

  const [getPostalCodesAndCities, { loading }] = useLazyQuery(
    PostalCodesAndCitiesQuery,
    {
      onCompleted: (result) => {
        if (result?.clientPostalCodesAndCities) {
          setPostalCodesAndCities(
            result.clientPostalCodesAndCities.map(
              ({ postal_code, city }: { postal_code: string; city: string }) =>
                `${postal_code} - ${city}`
            )
          );
        }
      },
    }
  );

  React.useEffect(() => {
    getPostalCodesAndCities({ variables: { query: debouncedSearch } });
  }, [debouncedSearch]);

  return (
    <Autocomplete
      loading={loading}
      options={postalCodesAndCities}
      autoHighlight
      getOptionLabel={(option) => option}
      value={value}
      onChange={onChange}
      inputValue={search}
      onInputChange={(_, newInputValue) => {
        setSearch(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="postal_code_city"
          label="Code postal - Ville"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default PostalCodeAndCityAutoComplete;
