import React from "react";
import { QueryResult, useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import { CalendarPicker } from "@mui/x-date-pickers";
import { CircularProgress } from "@mui/material";
import AlertDialog from "components/AlertDialog";
import DeliveryTimeSlotsQuery from "./DeliveryTimeSlotsQuery.gql";
import UpdateClientOrderTimeSlotMutation from "./UpdateClientOrderTimeSlotMutation.gql";
import NoSuitableTimeSlotMutation from "./NoSuitableTimeSlotMutation.gql";
import { DeliveryTimeSlot, Order } from "@types";
import TimeSlotSelector from "./TimeSlotSelector";
import ClientPortalConfigQuery from "pages/Delivery/ClientPortalConfigQuery.gql";

interface AppointmentProps {
  order: Order;
}

interface TimeSlotsQueryVariables {
  startDate: string;
  endDate: string;
}

const hasTimeSlot = (
  date: Dayjs,
  deliveryTimeSlots: [DeliveryTimeSlot]
): boolean => {
  return !deliveryTimeSlots.some(
    (timeSlot) => timeSlot.date === date.format("YYYY-MM-DD")
  );
};
const getTimeSlotKey = (timeSlot: DeliveryTimeSlot | null) => {
  return timeSlot ? timeSlot.date.concat("_", timeSlot.halfday) : null;
};

const Appointment = ({ order }: AppointmentProps) => {
  const confirmedDate = React.useMemo<dayjs.Dayjs | null>(() => {
    return order?.timeSlot?.date ? dayjs(order?.timeSlot?.date) : null;
  }, [order?.timeSlot?.date]);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const minDate = React.useMemo<dayjs.Dayjs>(
    () => dayjs(Date.now()).add(1, "day"),
    []
  );
  const [selectedDeliveryDate, setSelectedDeliveryDate] =
    React.useState<Dayjs | null>(confirmedDate ?? null);
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<string | null>(
    getTimeSlotKey(order?.timeSlot)
  );
  const [noSuitableTimeSlot, setNoSuitableTimeSlot] =
    React.useState<boolean>(false);

  const { loading: loadingClientPortalConfig, data: clientPortalConfigData } =
    useQuery(ClientPortalConfigQuery);

  const maxDate = React.useMemo<dayjs.Dayjs | undefined>(() => {
    const maxDateString =
      clientPortalConfigData?.clientPortalConfig?.contractorConfig
        ?.maxDeliveryDate;
    return maxDateString ? dayjs(maxDateString) : undefined;
  }, [
    clientPortalConfigData?.clientPortalConfig?.contractorConfig
      ?.maxDeliveryDate,
  ]);

  const getEndDateForFetch = React.useCallback(
    (monthDate: dayjs.Dayjs) => {
      // if maxDate exists and current month in the calendar is after or the same as maxDate, limit the end date to maxDate
      if (
        maxDate &&
        (monthDate.isAfter(maxDate, "month") ||
          monthDate.isSame(maxDate, "month"))
      ) {
        return maxDate.format("YYYY-MM-DD");
      }
      return monthDate.endOf("month").format("YYYY-MM-DD");
    },
    [maxDate]
  );

  const {
    loading,
    data: timeSlotQueryData,
    refetch: refetchTimeSlots,
  }: QueryResult<any, TimeSlotsQueryVariables> = useQuery(
    DeliveryTimeSlotsQuery,
    {
      variables: {
        startDate: minDate
          ? minDate.format("YYYY-MM-DD")
          : dayjs(Date.now()).format("YYYY-MM-DD"),
        endDate: getEndDateForFetch(
          selectedDeliveryDate ? selectedDeliveryDate : minDate
        ),
      },
    }
  );

  const deliveryTimeSlots = React.useMemo(() => {
    return timeSlotQueryData?.deliveryTimeSlots;
  }, [timeSlotQueryData]);

  const [
    updateClientOrderTimeSlotMutation,
    { loading: clientOrderTimeSlotMutationLoading },
  ] = useMutation(UpdateClientOrderTimeSlotMutation, {
    onError: () => toast.error("Une erreur est survenue"),
    onCompleted: (data: any) => {
      if (data?.updateClientOrderTimeSlot?.errorMessage) {
        toast.error(data.updateClientOrderTimeSlot.errorMessage);
      } else {
        toast.success("Votre créneau a bien été enregistré");
      }
    },
  });

  const [
    noSuitableTimeSlotMutation,
    { loading: noSuitableTimeSlotMutationLoading },
  ] = useMutation(NoSuitableTimeSlotMutation, {
    onError: () => toast.error("Une erreur est survenue"),
    onCompleted: (data) => {
      if (data?.updateClientOrderTimeSlot?.success) {
        toast.error(data.updateClientOrderTimeSlot.errorMessage);
      } else {
        toast.success("Votre choix à bien été enregistré");
      }
    },
  });

  const availableTimeSlotsForDate = React.useMemo(() => {
    if (!selectedDeliveryDate || !deliveryTimeSlots) {
      return [];
    }

    const availableDeliveryTimeSlotsForDate = deliveryTimeSlots
      .filter(
        (timeSlot: DeliveryTimeSlot) =>
          timeSlot.date === selectedDeliveryDate.format("YYYY-MM-DD")
      )
      .map((timeSlot: DeliveryTimeSlot) => ({
        ...timeSlot,
        key: getTimeSlotKey(timeSlot),
      }));

    return availableDeliveryTimeSlotsForDate || [];
  }, [selectedDeliveryDate, deliveryTimeSlots]);

  const handleSubmit = React.useCallback((): void => {
    const timeSlot = availableTimeSlotsForDate.find(
      (availableTimeSlot: DeliveryTimeSlot) =>
        availableTimeSlot.key === selectedTimeSlot
    );

    if (noSuitableTimeSlot) {
      setOpenDialog(true);
    } else {
      updateClientOrderTimeSlotMutation({
        variables: {
          clientOrderTimeSlot: {
            date: timeSlot.date,
            halfday: timeSlot.halfday,
            hour_from: timeSlot.hour_from,
            hour_to: timeSlot.hour_to,
          },
        },
      });
    }
  }, [
    availableTimeSlotsForDate,
    selectedTimeSlot,
    noSuitableTimeSlot,
    updateClientOrderTimeSlotMutation,
  ]);

  const handleSelectTimeSlot = (timeSlot: string): void => {
    if (noSuitableTimeSlot) {
      setNoSuitableTimeSlot(false);
    }
    setSelectedTimeSlot(timeSlot);
  };

  const toggleNoSuitableTimeSlot = (): void => {
    if (selectedTimeSlot) {
      setSelectedTimeSlot(null);
      setSelectedDeliveryDate(null);
    }
    setNoSuitableTimeSlot(!noSuitableTimeSlot);
  };
  const confirmedDateSameAsSelected = React.useMemo<boolean>(() => {
    if (!selectedDeliveryDate || !confirmedDate) {
      return false;
    }
    return (
      selectedDeliveryDate.isSame(confirmedDate, "day") &&
      selectedTimeSlot === getTimeSlotKey(order.timeSlot)
    );
  }, [selectedDeliveryDate, confirmedDate, selectedTimeSlot, order.timeSlot]);

  const isNewTimeslotNeeded = React.useCallback(
    (monthDate: Dayjs) => {
      if (deliveryTimeSlots.length === 0) {
        return true;
      }
      const lastDeliveryTimeSlotDate =
        deliveryTimeSlots[deliveryTimeSlots.length - 1].date;
      return dayjs(lastDeliveryTimeSlotDate).isBefore(monthDate);
    },
    [deliveryTimeSlots]
  );

  const getNextMonthData = React.useCallback(
    (monthDate: Dayjs) => {
      const endDate = getEndDateForFetch(monthDate);
      refetchTimeSlots({
        startDate: minDate.format("YYYY-MM-DD"),
        endDate: endDate,
      });
    },
    [refetchTimeSlots, minDate, getEndDateForFetch]
  );

  const maxCalendarDate = React.useMemo<dayjs.Dayjs | undefined>(() => {
    // if the current delivery date is after the maxDate, we need to extend the calendar to the date
    if (
      maxDate &&
      selectedDeliveryDate &&
      selectedDeliveryDate.isAfter(maxDate, "day")
    ) {
      return selectedDeliveryDate;
    }
    // this is either the maxDate or undefined(infinite calendar)
    return maxDate;
  }, [maxDate, selectedDeliveryDate]);

  React.useEffect(() => {
    refetchTimeSlots();
  }, [order.client.postal_code, refetchTimeSlots]);

  if (loading || loadingClientPortalConfig) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="appointment">
        <div className="appointment__calendar-container">
          <CalendarPicker
            date={selectedDeliveryDate}
            onChange={(selectedDate) => {
              setSelectedTimeSlot(null);
              setSelectedDeliveryDate(selectedDate);
              setNoSuitableTimeSlot(false);
            }}
            disablePast={true}
            minDate={minDate}
            maxDate={maxCalendarDate}
            shouldDisableDate={(date) => hasTimeSlot(date, deliveryTimeSlots)}
            shouldDisableYear={() => true}
            onMonthChange={(monthDate) => {
              setSelectedTimeSlot(null);
              if (isNewTimeslotNeeded(monthDate)) {
                getNextMonthData(monthDate);
              }
            }}
            views={["month", "day"]}
          />
        </div>
        <div className="appointment__time-slot-selector-container">
          <TimeSlotSelector
            selectedTimeSlot={selectedTimeSlot}
            handleSelectTimeSlot={handleSelectTimeSlot}
            noSuitableTimeSlot={noSuitableTimeSlot}
            toggleNoSuitableTimeSlot={toggleNoSuitableTimeSlot}
            availableTimeSlotsForDate={availableTimeSlotsForDate}
            loading={
              clientOrderTimeSlotMutationLoading ||
              noSuitableTimeSlotMutationLoading
            }
            handleSubmit={handleSubmit}
            confirmedDateSameAsSelected={confirmedDateSameAsSelected}
            status={order?.status}
          />
        </div>
      </div>
      <AlertDialog
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        title="Information"
        message="Vous avez choisi d'être rappelé. Vous pouvez annuler votre choix ou cliquez sur continuer pour être contacté ultérieurement par nos services et convenir d'un créneau."
        appearence="primary"
        callback={noSuitableTimeSlotMutation}
      />
    </>
  );
};

export default Appointment;
