import * as yup from "yup";

const validationSchema = yup.object({
  lastname: yup.string().required("Le nom est requis."),
  firstname: yup.string().required("Le prénom est requis."),
  address: yup.string().required("L'adresse de livraison est requise."),
  postal_code_city: yup
    .string()
    .nullable()
    .required("Le code postal et la ville sont requis."),
  floor: yup.number().required("L'étage doit être un nombre."),
  phone: yup.string().required("Le numéro de téléphone est requis."),
  email: yup
    .string()
    .email("Veuillez entrer une adresse email valide.")
    .required("L'adresse email est requise."),
});

export default validationSchema;
