import React from "react";
import Stepper, { Orientation } from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

interface AdditionnalInformation {
  google_review_url: string;
  office_phone: string;
  truck_line_phone: string;
}

interface OrderDelivery {
  status: string;
  additional_information: AdditionnalInformation;
}

interface TimelineViewProps {
  delivery: OrderDelivery;
}

interface StepProps {
  key: number;
  status: string;
  label: string;
  footerContent(additional_information: AdditionnalInformation): any;
}

interface DeliveryStep {
  key: number;
  status: string;
  label: string;
  footerContent(additional_information: AdditionnalInformation): any;
}

const TimelineViewFooter = ({ children }: any) => {
  return (
    <>
      <div className="timeline-view__footer">{children}</div>
    </>
  );
};

const steps: DeliveryStep[] = [
  {
    key: 1,
    status: "ASSIGNED",
    label: "Commande assignée en tournée",
    footerContent: (additional_information: AdditionnalInformation) => (
      <>
        <div>
          Votre commande est affectée en tournée
          <br />
          <br />
        </div>
        <div>
          Si vous souhaitez modifier le rendez-vous, merci de contacter le{" "}
          <b>service livraison au:</b>&nbsp;
          <a href={`tel:${additional_information.office_phone}`}>
            {additional_information.office_phone}
          </a>
        </div>
      </>
    ),
  },
  {
    key: 2,
    status: "IN_PROGRESS",
    label: "En cours de livraison",
    footerContent: (additional_information: AdditionnalInformation) => (
      <>
        <div>Votre commande est en cours de livraison</div>
        <div>Vous pouvez contacter:</div>

        {additional_information.truck_line_phone !==
          additional_information.office_phone && (
          <>
            <div>Le livreur au</div>
            <div>
              <a href={`tel:${additional_information.truck_line_phone}`}>
                {additional_information.truck_line_phone}
              </a>
            </div>
          </>
        )}

        <div>Le service livraison au</div>
        <div>
          <a href={`tel:${additional_information.office_phone}`}>
            {additional_information.office_phone}
          </a>
        </div>
      </>
    ),
  },
  {
    key: 3,
    status: "COMPLETED",
    label: "Livraison terminée",
    footerContent: (additional_information: AdditionnalInformation) => (
      <>
        <div>
          Votre livraison est terminée et nous espérons qu{"'"}elle a été à la
          hauteur de vos espérances!
        </div>
        <div>
          Nous vous serions très reconnaissants si vous preniez quelques
          instants pour évaluer la prestation de notre équipe en déposant un
          avis sur Google, merci d{"'"}avance!
        </div>
        <div className="timeline-view__review-link">
          <a
            href={additional_information.google_review_url}
            target="_blank"
            rel="noreferrer"
          >
            Laisser un avis sur google
          </a>
        </div>
      </>
    ),
  },
];

const TimelineView = ({ delivery }: TimelineViewProps) => {
  const activeStep = React.useMemo(() => {
    return steps.find((step) => delivery.status === step.status);
  }, [delivery.status]);

  return (
    <div className="timeline-view">
      <Stepper
        alternativeLabel
        className="timeline-view__stepper"
        activeStep={activeStep ? activeStep.key : 1}
        orientation="horizontal"
      >
        {steps.map(({ label, status }: StepProps) => (
          <Step key={status} className="timeline-view__step">
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep && (
        <TimelineViewFooter>
          {activeStep.footerContent(delivery.additional_information)}
        </TimelineViewFooter>
      )}
    </div>
  );
};

export default TimelineView;
