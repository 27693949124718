import React from "react";
import { OfficeConfig } from "@types";
interface FooterLogoProps {
  src: string;
}

const FooterLogo = (props: FooterLogoProps) => {
  return <img className="footer__logo" src={props.src} />;
};

interface FooterProps {
  officeConfig: OfficeConfig;
}

const Footer = (props: FooterProps) => {
  return (
    <div className="footer">
      <FooterLogo src={props.officeConfig.logoUrl} />
      <div>
        {props.officeConfig.name} - {props.officeConfig.fullAddress} <br />
        {props.officeConfig.phone}
      </div>
    </div>
  );
};

export default Footer;
