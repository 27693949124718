import React from "react";

import { ReactComponent as LogoSvg } from "assets/logo.svg";

const Logo = () => {
  return (
    <div className="logo">
      <LogoSvg />
    </div>
  );
};

export default Logo;
