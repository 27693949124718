import { Order } from "@types";
import React from "react";
import ScheduleDeliveryTabs from "./ScheduleDeliveryTabs";

interface ScheduleDeliveryProps {
  order: Order;
}

const ScheduleDelivery = ({ order }: ScheduleDeliveryProps) => {
  return (
    <div className="schedule-delivery">
      <ScheduleDeliveryTabs order={order} />
    </div>
  );
};

export default ScheduleDelivery;
