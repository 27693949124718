import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

const makeApolloClient: () => ApolloClient<NormalizedCacheObject> = () => {
  const apolloLink = createHttpLink({
    uri: `${process.env.REACT_APP_APOLLO_SERVER_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    const urlSearchParams = new URLSearchParams(document.location.search);
    /* intentional obstruction */
    const token = urlSearchParams.get("xct");

    return {
      headers: {
        ...headers,
        "X-Client-Token": token,
      },
    };
  });

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: authLink.concat(apolloLink),
    cache: new InMemoryCache(),
  });

  return apolloClient;
};

export default makeApolloClient;
